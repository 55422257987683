<template>

  <main class="constrain p-0">
    <router-view v-slot="{ Component }">
      <transition name="appear" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
  </main>

</template>

<script>

import Navigation from '@/components/structure/Navigation.vue';

export default {
  components: { Navigation },
};
</script>
