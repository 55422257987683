<template>

  <div class="navbar constrain">

    <Go :to="'/'" aria-label="Home" class="mx-auto">
      <Logo class="logo" />
    </Go>

    <Language class="Language" />

  </div>

</template>

<script>
import Logo from '@/assets/images/UPD-Logo-White.svg';
import Language from '../utils/Language.vue';

export default {
  components: {
    Language, Logo,
  },
  data: () => ({
    active: false,
  }),
};
</script>

<style lang="scss" scoped>
.navbar {
  padding-top: 0;
  padding-bottom: 0;
  color: white;

  .logo {
    width: 100%;
    height: 90px;
    margin: 0 auto;
    object-fit: contain;
    object-position: center;
  }

}

.Language {
  position: absolute;
  top: 15px;
  right: 10px;
}

</style>
