/* eslint-disable max-len */

import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import { createHead } from '@vueuse/head';
import { createI18n } from 'vue-i18n';
import { createGtm } from 'vue-gtm';
import VueScrollTo from 'vue-scrollto';
import axios from 'axios';
import VueAxios from 'vue-axios';
import InlineSvg from 'vue-inline-svg';
import smoothscroll from 'smoothscroll-polyfill';
import { } from 'unfocus';

import App from '@/App.vue';
import Go from '@/components/utils/Go.vue';

import store from './store';

import messagesDE from './assets/language/de.json';

import './assets/styles/main.scss';

// polyfills
// --------------------------------
smoothscroll.polyfill();

// i18n
// --------------------------------

const messages = { de: messagesDE };

const userLanguages = (navigator.languages || [navigator.language]).map((l) => l.split('-')[0]);
const appLanguages = Object.keys(messages);
const locale = userLanguages.find((element) => appLanguages.includes(element)) || appLanguages[0];

const i18n = createI18n({ locale, messages });

// router
// --------------------------------

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', component: () => import('./components/pages/Start.vue') },
    { path: '/question/:index', component: () => import('./components/pages/Question.vue'), props: true },
    { path: '/finish', component: () => import('./components/pages/Finish.vue') },
    { path: '/terms', component: () => import('./components/pages/Terms.vue') },
    { path: '/register', component: () => import('./components/pages/Register.vue') },
    { path: '/thanks', component: () => import('./components/pages/Thanks.vue') },
    { path: '/:pathMatch(.*)*', component: () => import('./components/structure/NotFound.vue') },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) return { el: to.hash, behavior: 'smooth' };
    if (savedPosition) return savedPosition;
    return { top: 0 };
  },
});

// app
// --------------------------------
const app = createApp(App);

const head = createHead();
app.use(head);
app.use(i18n);
app.use(router);
app.use(store);
app.use(VueScrollTo);
app.use(VueAxios, axios);
/*
app.use(createGtm({
  id: 'GTM-XXXXXXX',
  vueRouter: router,
}));
*/
app.component('InlineSvg', InlineSvg);

window[atob('cGF0cmljaw==')] = atob('U3RpbGxoYXJ0IHN0eWxlZCBoYXJk');

// i18n
// --------------------------------

// custom components
// --------------------------------
app.component('Go', Go);
app.component('Image', Image);

app.mount('#app');
