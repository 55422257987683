import { createStore, createLogger } from 'vuex';
import axios from 'axios';

const debug = process.env.NODE_ENV !== 'production';

const getDefaultState = () => ({
  answers:
    {
      1: '',
      2: '',
      3: '',
      4: '',
      5: '',
      6: '',
      7: '',
      8: '',
      9: '',
    },
  correct:
    {
      1: false,
      2: false,
      3: false,
      4: false,
      5: false,
      6: false,
      7: false,
      8: false,
      9: false,
    },
  seconds: 0,
  score: 0,
  form: {
    firstname: '',
    lastname: '',
    email: '',
  },
});

export default createStore({
  state: getDefaultState(),
  strict: false,
  plugins: debug ? [createLogger()] : [],
  actions: {
    register({ state }) {
      return axios.post(
        'https://upd-quiz.ch/api/de/ajax/forms/new_request',
        {
          form: 'Quiz',
          first_name: state.form.firstname,
          last_name: state.form.lastname,
          email: state.form.email,
        },
      );
    },
  },
  mutations: {
    resetState(state) {
      Object.assign(state, getDefaultState());
    },
  },
});
